<template>
  <div id="main">
    <div id="topGlass" :style="{opacity: isHome && (goTopShow || cursorInTop) ? 1 : 0}"></div>
    <div id="top" :class="[isHome ? 'transparent' : 'white']" @mouseenter="cursorInTop = true" @mouseleave="cursorInTop = false">
      <img v-if="isHome" class="logo" :src='require("@/assets/img/index/logo.png")' @click='checkMenu("/home")'/>
      <img v-else class="logo" :src='require("@/assets/img/index/logo-white.png")' @click='checkMenu("/home")'/>
      <div id="menu" :class="isHome ? '' : 'white'">
        <div class="menu-item product"
             @click='checkMenu("/product/t20")'
             :class='(activeMenu.indexOf("/product") == 0 && activeMenu.indexOf("/product/e") == -1) || hover.detailType=="product" ? "active" : ""'
             @mouseenter='enterMenu("product")' @mouseleave='leaveMenu("product")'>
          <span class="text">澜渟产品</span>

          <div class="top-hover-product" :class="isHome ? 'home' : ''">
            <div id="product-detail" v-if='hover.type=="product"' @mouseenter='enterMenuDetail("product")'
                 @mouseleave="leaveMenuDetail('product')">
              <p class="product-detail-item wrightin-t20" @click.stop='checkMenu("/product/t20")'>
                <b class="img-container">
                  <img :src="require('@/assets/img/index/product-t20.png')"/>
                </b>
                <span>T20 series</span>
                <img class="new-icon" :src="require('@/assets/img/index/new-icon.png')"/>
              </p>
              <p class="product-detail-item wrightin-luolan" @click.stop='checkMenu("/product/luolan")'>
                <b class="img-container">
                  <img :src="require('@/assets/img/index/product-luolan.png')"/>
                </b>
                <span>洛澜</span>
              </p>
              <p class="product-detail-item wrightin-two" @click.stop='checkMenu("/product/wrightinTwo")'>
                <b class="img-container">
                  <img :src="require('@/assets/img/index/product-wrightinTwo.png')"/>
                </b>
                <span>T20 Max</span>
              </p>
              <p class="product-detail-item wrightin" @click.stop='checkMenu("/product/wrightin")'>
                <b class="img-container">
                  <img :src="require('@/assets/img/index/product-wrightin.png')"/>
                </b>
                <span>初澜</span>
              </p>
              <p class="product-detail-item kegel" @click.stop='checkMenu("/product/kegel")'>
                <b class="img-container">
                  <img :src="require('@/assets/img/index/product-kegel.png')"/>
                </b>
                <span>澜渟凯格尔球</span>
              </p>
              <p class="product-detail-item wireless" @click.stop='checkMenu("/product/wireless")'>
                <b class="img-container">
                  <img :src="require('@/assets/img/index/product-wireless.png')"/>
                </b>
                <span>无线产后恢复仪</span>
              </p>
            </div>
          </div>
        </div>
        <div class="menu-item productE"
             :class='activeMenu.indexOf("/product/e")==0 || hover.detailType=="productE" ? "active" : ""'
             @click='checkMenu("/product/e10")'
             @mouseenter='enterMenu("productE")' @mouseleave='leaveMenu("productE")'>
          <span class="text">沐澜产品</span>

          <div class="top-hover-product-e" :class="isHome ? 'home' : ''">
            <div id="productE-detail" v-if='hover.type=="productE"' @mouseenter='enterMenuDetail("productE")'
                 @mouseleave="leaveMenuDetail('productE')">
              <p class="product-detail-item" @click.stop='checkMenu("/product/e10")'>
                <b class="img-container">
                  <img :src="require('@/assets/img/index/product-e10.png')"/>
                </b>
                <span>E10 series</span>
                <img class="new-icon" :src="require('@/assets/img/index/new-icon.png')"/>
              </p>
              <p class="product-detail-item" @click.stop='checkMenu("/product/ekegel")'>
                <b class="img-container">
                  <img :src="require('@/assets/img/index/product-ekegel.png')"/>
                </b>
                <span>沐澜凯格尔球</span>
              </p>
            </div>
          </div>
        </div>
        <div class="menu-item technology"
             :class='activeMenu=="/technology" || hover.detailType=="technology" ? "active" : ""'
             @click='checkMenu("/technology")'
             @mouseenter='enterMenu("technology")' @mouseleave='leaveMenu("technology")'>
          <span class="text">医研科技</span>

          <div class="top-hover-technology" :class="isHome ? 'home' : ''">
            <div id="technology-detail" v-if='hover.type=="technology"' @mouseenter='enterMenuDetail("technology")'
                 @mouseleave="leaveMenuDetail('technology')">
              <p class="technology-detail-item" @click.stop='checkMenu("/app")'>
                澜渟 App
              </p>
              <p class="technology-detail-item" @click.stop='checkMenu("/technology")'>
                科研技术
              </p>
            </div>
          </div>
        </div>
        <div class="menu-item about"
             :class='activeMenu.indexOf("/about")==0 || hover.detailType=="about" ? "active" : ""'
             @click='checkMenu("/about/info")'
             @mouseenter='enterMenu("about")' @mouseleave='leaveMenu("about")'>
          <span class="text">认识澜渟</span>

          <div class="top-hover-about" :class="isHome ? 'home' : ''">
            <div id="about-detail" v-if='hover.type=="about"' @mouseenter="enterMenuDetail('about')"
                 @mouseleave="leaveMenuDetail('about')">
              <p class="about-detail-item" @click.stop='checkMenu("/about/info")'>
                <b class="img-container">
                  <img :src="require('@/assets/img/index/about-info.png')"/>
                </b>
                <span>公司简介</span>
              </p>
              <p class="about-detail-item" @click.stop='checkMenu("/about/consultation")'>
                <b class="img-container">
                  <img :src="require('@/assets/img/index/about-consultation.png')"/>
                </b>
                <span>品牌资讯</span>
              </p>
              <p class="about-detail-item" @click.stop='checkMenu("/about/contact")'>
                <b class="img-container">
                  <img :src="require('@/assets/img/index/about-contact.png')"/>
                </b>
                <span>联系我们</span>
              </p>
              <p class="about-detail-item" @click.stop='checkMenu("/about/join")'>
                <b class="img-container">
                  <img :src="require('@/assets/img/index/about-join.png')"/>
                </b>
                <span>加入我们</span>
              </p>
              <p class="about-detail-item" @click.stop='checkMenu("/about/responsibility")'>
                <b class="img-container">
                  <img :src="require('@/assets/img/index/about-responsibility.png')"/>
                </b>
                <span>社会责任</span>
              </p>
            </div>
          </div>
        </div>
        <div class="menu-item empower" :class='activeMenu.indexOf("/empower")==0?"active":""' @click='checkMenu("/empower")'
             @mouseenter='enterMenu("empower")' @mouseleave='leaveMenu("empower")'>
          <span class="text">授权查询</span>
        </div>
      </div>
      <span id="hotLine" :class="[isHome ? 'home' : '']">
        <span class="text">服务热线：400-133-5668</span>
        <span class="img" @click='openUrl("https://lanting.tmall.com")'></span>
      </span>
    </div>

    <div id="content" :class="[isHome ? 'home-content' : '']">
      <router-view ref="page" @rollInit="rollInitEvent" @rollEnd="rollEndEvent" @rollTop="rollTopEvent" @rollPage="rollPageEvent" v-model="rollDisable"/>
    </div>
    <div class="home-tel" v-if="isHome" :style="{display: pageBottomDisplay}" @click='checkMenu("/about/contact")'>
      <img alt :src="require('@/assets/img/index/home-tel.png')"/>
      <p>线下经销商合作</p>
      <div>
        <div class="bg"></div>
        <div class="mask"></div>
        <div class="text">
          招商电话：17372217350 <img alt :src="require('@/assets/img/home/mall-to.png')"/>
        </div>
      </div>
    </div>
    <div class="t20-other" v-if="activeMenu == '/product/t20'"
         :style="{display: pageBottomDisplay == 'flex' ? 'block' : 'none'}">
      <div class="wireless">
        <video src="//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-t20-5.mp4" muted autoplay loop
               @contextmenu.prevent
               playsinline="true" preload="auto" x5-video-player-type="h5" webkit-playsinline="true" x5-playsinline="true"
        ></video>
        <img class="bg" alt :src="require('@/assets/img/product/t20/wireless-bg.png')"/>
        <p class="title">无线体验升级 随享无拘无束</p>
      </div>
      <div class="design">
        <p class="sub-title">6 重设计 操作更简单</p>
        <div class="design-list">
          <div class="design-item">
            <div class="img-container">
              <img alt :src="require('@/assets/img/product/t20/design-1.png')"/>
            </div>
            <p class="title">无线一体化设计</p>
            <p class="sub-title">一键开启 摆脱有线束缚</p>
          </div>
          <div class="design-item">
            <div class="img-container">
              <img alt :src="require('@/assets/img/product/t20/design-2.png')"/>
            </div>
            <p class="title">内置自体消毒</p>
            <p class="sub-title">UVC 灯珠 紫外线消毒</p>
          </div>
          <div class="design-item">
            <div class="img-container">
              <img alt :src="require('@/assets/img/product/t20/design-3.png')"/>
            </div>
            <p class="title">充电收纳一体</p>
            <p class="sub-title">保护机身 长效护航</p>
          </div>
          <div class="design-item">
            <div class="img-container">
              <img alt :src="require('@/assets/img/product/t20/design-4.png')"/>
            </div>
            <p class="title">无线温热探头</p>
            <p class="sub-title">入体更舒适</p>
          </div>
          <div class="design-item">
            <div class="img-container">
              <img alt :src="require('@/assets/img/product/t20/design-5.png')"/>
            </div>
            <p class="title">1000V 耐压技术</p>
            <p class="sub-title">科技实力享体验</p>
          </div>
          <div class="design-item">
            <div class="img-container">
              <img alt :src="require('@/assets/img/product/t20/design-6.png')"/>
            </div>
            <p class="title">温柔震感提醒</p>
            <p class="sub-title">训练异常实时感知</p>
          </div>
        </div>
      </div>
      <div class="research">
        <img class="research-bg" alt :src="require('@/assets/img/product/t20/research-bg.png')"/>
        <p class="title">国际权威验证 专研盆底紧致</p>
        <p class="sub-title">《电刺激生物反馈在产后盆底 功能康复中的临床效果评价》</p>
        <div class="research-btn">尿失禁临床研究[1]</div>
      </div>
      <div class="zhuanli">
        <img class="zhuanli-bg" alt :src="require('@/assets/img/product/t20/zhuanli-bg.png')"/>
        <p class="title">医疗器械认证及专利</p>
        <p class="sub-title">严苛品质</p>
        <div class="list">
          <div class="item">
            <p>盆底康复治疗仪</p>
            <p>二类医疗器械</p>
          </div>
          <div class="item">
            <p>发明专利</p>
            <p>ZL 2023 1 1543573.4</p>
          </div>
          <div class="item">
            <p>发明专利</p>
            <p>ZL 2023 1 0913296.5</p>
          </div>
          <div class="item">
            <p>发明专利</p>
            <p>ZL 2023 1 1802368.5</p>
          </div>
          <div class="item">
            <p>外观设计专利</p>
            <p>ZL202330448897.4</p>
          </div>
          <div class="item">
            <p>外观设计专利</p>
            <p>ZL 2023 3 0481048.9</p>
          </div>
        </div>
      </div>
    </div>
    <div class="e10-other" v-if="activeMenu == '/product/e10'"
         :style="{display: pageBottomDisplay == 'flex' ? 'block' : 'none'}">
      <div class="sm-ck">
        <div class="left">
          <img alt :src="require('@/assets/img/product/e10/left.png')"/>
          <p>治疗阴道松弛症</p>
        </div>
        <div class="right">
          <video src="//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-e10-2.mp4" autoplay muted loop
                 @contextmenu.prevent
                 playsinline="true" preload="auto" x5-video-player-type="h5" webkit-playsinline="true" x5-playsinline="true"
          ></video>
          <p>治疗腹直肌分离</p>
        </div>
      </div>
      <div class="full-video">
        <video src="//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-e10-3.mp4" autoplay muted loop
               @contextmenu.prevent
               playsinline="true" preload="auto" x5-video-player-type="h5" webkit-playsinline="true" x5-playsinline="true"
        ></video>
        <p class="title">生电联合技术</p>
        <p class="sub-title">激活细胞活性，肌肉收放自如</p>
      </div>
      <div class="spec">
        <p class="title">产品组件</p>
        <img class="person" alt :src="require('@/assets/img/product/e10/person.png')"/>
        <div class="content">
          <div class="top">
            <img alt :src="require('@/assets/img/product/e10/spec-top.png')"/>
            <div class="title">独立主机</div>
          </div>
          <div class="bottom">
            <div class="left">
              <img alt :src="require('@/assets/img/product/e10/spec-left.png')"/>
              <div class="title">独立芯片</div>
            </div>
            <div class="right">
              <div class="top">
                <img alt :src="require('@/assets/img/product/e10/spec-right-top.png')"/>
                <div class="title">纯电体验</div>
                <div class="sub-title">修复更舒适</div>
              </div>
              <div class="bottom">
                <img alt :src="require('@/assets/img/product/e10/spec-right-bottom.png')"/>
                <div class="title">贴心升级</div>
                <div class="sub-title">修复计划<br/>实时提醒</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="zhuanli">
        <img class="zhuanli-bg" alt :src="require('@/assets/img/product/e10/zhuanli.png')"/>
        <p class="title">医疗器械认证及专利</p>
        <p class="sub-title">严苛品质</p>
        <div class="list">
          <div class="item">
            <p>盆底康复治疗仪</p>
            <p>二类医疗器械</p>
          </div>
          <div class="item">
            <p>盆底评估训练软件</p>
            <p>二类医疗器械</p>
          </div>
          <div class="item">
            <p>阴道电极</p>
            <p>二类医疗器械</p>
          </div>
          <div class="item">
            <p>理疗用体表电级</p>
            <p>一类医疗器械备案</p>
          </div>
        </div>
      </div>
    </div>
    <div id="pageBottom" :style="{display: pageBottomDisplay}">
      <div class="top">
        <img alt :src='require("@/assets/img/index/logo.png")' @click='checkMenu("/home")'/>
      </div>
      <div class="content">
        <div class="left">
          <div class="social-media">
            <div class="normal-text">{{isMulan ? '沐澜' : ''}}社交媒体</div>
            <div class="list">
              <template v-for="(socialMedia, index) in socialMediaConfig">
                <div @mouseenter="hoverImgShow($event, socialMedia.name)" @mouseleave="hoverImgHide"
                     class="item" :class="socialMedia.name"
                     v-if="!isMulan || (isMulan && !socialMedia.mulanHide)"></div>
              </template>
            </div>
          </div>
          <div class="contact">
            <div class="item">
              <div class="normal-text">服务热线：周一至周日 9:00-18:00</div>
              <div class="big-text">400-133-5668</div>
            </div>
            <div class="gap">
              <div class="line"></div>
            </div>
            <div class="item">
              <div class="normal-text">经销合作：</div>
              <div class="big-text">17372217350</div>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="link-group" v-for="(linkGroup, linkGroupIndex) in bottomLinkConfig" :key="linkGroupIndex">
            <div class="title normal-text">{{ linkGroup.group }}</div>
            <div class="link-list">
              <div class="link-item" v-for="(linkItem, linkIndex) in linkGroup.linkList" :key="linkIndex">
                <span class="small-text" v-if="linkGroup.type=='menu'" @click="checkMenu(linkItem.link)">{{ linkItem.name }}</span>
                <span class="small-text" v-else @click="openUrl(linkItem.link)">{{ linkItem.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title normal-text">下载应用</div>
          <div class="app-list">
            <div class="app-item" @mouseenter="hoverImgShow($event, '', 'app')" @mouseleave="hoverImgHide">
              <img alt :src="require('@/assets/img/index/app-lt.png')"/>
              <span class="text small-text active">澜渟 APP</span>
            </div>
            <div class="app-item" @mouseenter="hoverImgShow($event, '', 'app-doctor')" @mouseleave="hoverImgHide">
              <img alt :src="require('@/assets/img/index/app-ltd.png')"/>
              <span class="text small-text active">澜渟医生 APP</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <p>互联网药品信息服务资格证书：(苏)-非经营性-2023-0070</p>
        <p @click='openUrl("https://beian.miit.gov.cn/#/Integrated/index")'>Copyright © 2013-{{ copyRightYear }} 南京麦豆健康科技有限公司 版权所有 苏ICP备 17044321 号</p>
        <p>投诉举报电话: 12331</p>
      </div>
      <div class="hover-img" :style="{display: hoverImg.isShow ? 'block' : 'none', top: hoverImg.top, left: hoverImg.left}">
        <img :src="hoverImg.qrcodePath" alt="二维码"/>
        <img :src="require('@/assets/img/index/angle.png')" alt class="angle">'
      </div>
    </div>
    <div id="goTop" v-if="goTopShow" @click="goTop()" :class="toBottom ? 'white' : ''">
      <img class="img" v-if="!toBottom" :src='require("@/assets/img/index/to-top.png")'>
      <img class="img-white" v-else :src='require("@/assets/img/index/to-top-white.png")'>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cursorInTop: false,
      activeMenu: '/home',
      hover: {
        type: 'product',
        isShow: false,
      },
      rollDisable: false,
      scrollTop: 0,
      pageBottomDisplay: 'none',
      rollPage: 1,
      goTopShow: false,
      toBottom: false,
      copyRightYear: new Date().getFullYear(),
      hoverImg: {
        isShow: false,
        qrcodePath: '',
        top: 0,
        left: 0,
      },
      isHome: true,
      isMulan: false,
      socialMediaConfig: [
        {
          name: 'wechat',
          type: 'qrcode',
          mulanHide: true,
        },
        {
          name: 'tiktok',
          type: 'qrcode',
          mulanHide: false,
        },
        {
          name: 'xiaohongshu',
          type: 'qrcode',
          mulanHide: false,
        },
        {
          name: 'weibo',
          type: 'qrcode',
          mulanHide: true,
        },
        {
          name: 'zhihu',
          type: 'qrcode',
          mulanHide: false,
        },
      ],
      bottomLinkConfig: [
        {
          group: '澜渟产品',
          type: 'menu',
          linkList: [
            {
              name: 'T20 series',
              link: '/product/t20',
            },
            {
              name: '洛澜',
              link: '/product/luolan',
            },
            {
              name: 'T20 Max',
              link: '/product/wrightinTwo',
            },
            {
              name: '初澜',
              link: '/product/wrightin',
            },
            {
              name: '无线产后恢复仪',
              link: '/product/wireless',
            },
            {
              name: '澜渟凯格尔球',
              link: '/product/kegel',
            },
          ],
        },
        {
          group: '沐澜产品',
          type: 'menu',
          linkList: [
            {
              name: 'E10 series',
              link: '/product/e10',
            },
            {
              name: '沐澜凯格尔球',
              link: '/product/ekegel',
            },
          ],
        },
        {
          group: '医研科技',
          type: 'menu',
          linkList: [
            {
              name: '澜渟 App',
              link: '/app',
            },
            {
              name: '核心技术',
              link: '/technology',
            },
          ],
        },
        {
          group: '关于我们',
          type: 'menu',
          linkList: [
            {
              name: '公司简介',
              link: '/about/info',
            },
            {
              name: '品牌资讯',
              link: '/about/consultation',
            },
            {
              name: '联系我们',
              link: '/about/contact',
            },
            {
              name: '加入我们',
              link: '/about/join',
            },
            {
              name: '社会责任',
              link: '/about/responsibility',
            },
          ],
        },
        {
          group: '友情链接',
          type: 'friend',
          linkList: [
            {
              name: '麦澜德',
              link: 'https://www.medlander.com',
            },
            {
              name: '佳澜健康',
              link: 'http://www.jialan365.com',
            },
            {
              name: '锐诗得',
              link: 'http://www.reseader.com',
            },
          ],
        },
      ],
    };
  },
  mounted() {
    window.addEventListener('scroll', this.bindScrollWindow, true);
  },
  methods: {
    rollPageEvent(page) {
      if (page > (this.isHome ? 2 : 1)) {
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
    },

    rollInitEvent() {
      this.pageBottomDisplay = 'none';
      this.toBottom = false;
      this.rollDisable = false;
      this.$refs.page.currentPage = 1;
    },

    rollEndEvent() {
      this.pageBottomDisplay = 'flex';
    },

    rollTopEvent() {
      this.pageBottomDisplay = 'none';
      this.toBottom = false;
    },
    /**
     监听页面滚动条
     @param
     @return
     */
    bindScrollWindow() {
      this.scrollTop = window.scrollY;
      if (!this.goTopShow) {
        this.goTopShow = window.scrollY > window.screen.availHeight * .06;
      }
      const rem = document.documentElement.style.fontSize.replace('px', '') * 1;
      const fullHeight = window.innerHeight;
      const pageBottomTop = document.querySelector('#pageBottom').getBoundingClientRect().top;
      if (fullHeight - pageBottomTop > 1.15 * rem) {
        this.toBottom = true;
      } else {
        this.toBottom = false;
      }
      this.$nextTick(() => {
        this.rollDisable = this.scrollTop != 0 && this.pageBottomDisplay == 'flex';
      });
    },
    /**
     选中菜单
     @param {(url: string) => string} menu 菜单
     @return
     */
    checkMenu(menu) {
      if (this.activeMenu == menu) {
        this.goTop();
      } else {
        this.jump(menu);
      }
      this.leaveMenuDetail();
    },
    /**
     页面跳转
     @param {string} path 跳转路径
     @return
     */
    jump(path) {
      this.$router.push({path: path});
    },
    /**
     打开链接
     @param {string} url 链接
     @return
     */
    openUrl(url) {
      window.open(url);
    },
    /**
     置顶
     @param
     @return
     */
    goTop() {
      this.rollDisable = false;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      if (this.activeMenu == '/product/e10' ||
        this.activeMenu == '/product/ekegel' ||
        this.activeMenu == '/product/t20') {
        this.$refs.page.$refs.pageChild.currentPage = 1;
      } else {
        this.$refs.page.currentPage = 1;
      }
    },
    /**
     鼠标移入菜单
     @param {string} menu 菜单
     @return
     */
    enterMenu(menu) {
      this.hover.isShow = true;
      this.hover.type = menu;
    },
    /**
     鼠标移出菜单
     @param
     @return
     */
    leaveMenu(menu) {
      this.hover.isShow = false;
    },

    /**
     鼠标移入菜单详情
     @param
     @return
     */
    enterMenuDetail(detailType) {
    },
    /**
     鼠标移出菜单详情
     @param
     @return
     */
    leaveMenuDetail(menu) {
    },

    hoverImgShow(event, qrcodeName, appName = '') {
      if (this.hoverImg.isShow) {
        return;
      }
      const rem = document.documentElement.style.fontSize.replace('px', '') * 1;
      this.hoverImg.isShow = true;
      if (appName === '') {
        this.hoverImg.qrcodePath = require('@/assets/img/index/social-media/qrcode/' + (this.isMulan ? 'mulan_' : '') + qrcodeName + '.png');
      } else {
        this.hoverImg.qrcodePath = require('@/assets/img/index/qrcode-' + appName + '.png');
      }
      this.hoverImg.left = event.target.offsetLeft - (1.2 * rem - event.target.offsetWidth) / 2 + 'px';
      this.hoverImg.top = event.target.offsetTop - ((1.2 + 0.12 + 0.1) * rem) + 'px';
    },

    hoverImgHide() {
      this.hoverImg.isShow = false;
      this.hoverImg.left = 0;
      this.hoverImg.top = 0;
    },
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        this.goTopShow = false;
        this.activeMenu = newVal.path;
        if (this.activeMenu == '/product/e10' || this.activeMenu == '/product/ekegel') {
          this.isMulan = true;
        } else {
          this.isMulan = false;
        }
        if (this.activeMenu == '/home') {
          this.isHome = true;
        } else {
          this.isHome = false;
        }
        if (this.activeMenu == '/home' ||
          this.activeMenu == '/product/e10' ||
          this.activeMenu == '/product/ekegel' ||
          this.activeMenu == '/product/t20') {
          this.pageBottomDisplay = 'none';
        } else {
          this.pageBottomDisplay = 'flex';
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#main {
  height: 100%;
}

#topGlass {
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: .72rem;
  transition: all 0.3s linear;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: saturate(180%) blur(1rem);
  border-bottom: 0.01rem solid rgba(255, 255, 255, 0.1);
}

#top {
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: .72rem;

  &.transparent {
    background-color: transparent;
  }

  &.white {
    background-color: #fff;
    border-bottom: 0.01rem solid rgba(255, 255, 255, 0.1);
  }

  &.black {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: saturate(180%) blur(1rem);
  }

  > .logo {
    position: absolute;
    top: 0.21rem;
    left: 0.9rem;
    cursor: pointer;
    height: 0.3rem;
  }

  > #menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 5.2rem;
    height: .72rem;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.white {
      > .menu-item {
        > .text {
          color: #333;
        }

        &:hover, &.active {
          &:after {
            background-color: #333;
          }
        }
      }
    }

    > .menu-item {
      position: relative;
      height: .72rem;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0.56rem;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 0;
        background-color: #fff;
        height: 0.02rem;
        -webkit-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }

      > .text {
        font-weight: 400;
        font-size: 0.18rem;
        color: #fff;
        line-height: 0.25rem;
      }

      .top-hover-product,
      .top-hover-product-e,
      .top-hover-technology,
      .top-hover-about {
        position: fixed;
        z-index: 97;
        top: 0.72rem;
        left: 0;
        right: 0;
        overflow: hidden;
        height: 0;
        transition: all 0.2s linear;

        &.home {
          > #product-detail,
          > #productE-detail {
            background: rgba(0, 0, 0, 0.6);
            backdrop-filter: saturate(180%) blur(1rem);

            > .product-detail-item {
              > span {
                color: rgba(255, 255, 255, 0.8);
              }

              &:hover {
                > span {
                  color: #fff;
                }
              }
            }
          }

          > #about-detail {
            background: rgba(0, 0, 0, 0.6);
            backdrop-filter: saturate(180%) blur(1rem);

            > .about-detail-item {
              > span {
                color: rgba(255, 255, 255, 0.8);
              }

              &:hover {
                > span {
                  color: #fff;
                }
              }
            }
          }

          > #technology-detail {
            background: rgba(0, 0, 0, 0.6);
            backdrop-filter: saturate(180%) blur(1rem);

            > .technology-detail-item {
              color: rgba(255, 255, 255, 0.8);

              &:hover {
                color: #fff;
              }
            }
          }
        }

        > #product-detail {
          padding: 0 3.95rem;
        }

        > #productE-detail {
          padding: 0 7.99rem;
        }

        > #product-detail,
        > #productE-detail {
          position: absolute;
          left: 0;
          right: 0;
          height: 1.98rem;
          background: rgba(255, 255, 255, 0.96);
          display: flex;
          justify-content: space-between;
          align-items: center;

          > .product-detail-item {
            width: 1.2rem;
            height: 1.34rem;
            position: relative;
            cursor: pointer;

            &:hover {
              > b {
                > img {
                  transform: scale(1.1);
                }
              }
            }

            > .new-icon {
              width: 0.29rem;
            }

            > b {
              position: absolute;
              top: 0;
              width: 1.2rem;
              overflow: hidden;

              > img {
                transition: all 0.4s;
                width: 100%
              }
            }

            > span {
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              text-align: center;
              font-weight: 400;
              font-size: 0.16rem;
              color: #666;
              line-height: 0.22rem;
            }

            &:hover {
              > span {
                color: #333;
              }
            }
          }
        }

        > #about-detail {
          position: absolute;
          left: 0;
          right: 0;
          height: 2.08rem;
          background: rgba(255, 255, 255, 0.96);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 4.26rem;

          > .about-detail-item {
            width: 1.48rem;
            height: 1.44rem;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > .img-container {
              width: 1.48rem;
              height: 1.1rem;
              overflow: hidden;
              display: flex;
              border-radius: 0.08rem;

              >img {
                width: 100%;
                transition: all 0.4s;
              }
            }

            > span {
              text-align: center;
              line-height: .22rem;
              font-size: .16rem;
              color: #666;
            }

            &:hover {
              > span {
                color: #333;
              }

              > .img-container {
                > img {
                  transform: scale(1.1);
                }
              }
            }
          }
        }

        > #technology-detail {
          position: absolute;
          left: 8.7rem;
          height: 1.16rem;
          width: 1.8rem;
          background: rgba(255, 255, 255, 0.96);
          display: flex;
          flex-direction: column;
          align-items: center;

          > .technology-detail-item {
            height: 0.58rem;
            line-height: 0.58rem;
            font-size: 0.16rem;
          }
        }
      }

      &:hover, &.active {
        &:after {
          width: 100%;
        }

        > .text {
          font-weight: 500;
        }
      }

      &:hover {
        .top-hover-product,
        .top-hover-product-e {
          height: 1.98rem;
        }

        .top-hover-about {
          height: 2.08rem;
        }

        .top-hover-technology {
          height: 1.16rem;
        }
      }
    }
  }

  > #hotLine {
    position: absolute;
    top: .23rem;
    right: 0.9rem;
    width: 2.49rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .text {
      font-weight: 400;
      font-size: 0.18rem;
      color: #333;
      line-height: 0.25rem;
    }

    > .img {
      width: .2rem;
      height: .2rem;
      background: url(./assets/img/index/hotline-black.png) no-repeat center center;
      background-size: 100% 100%;
      cursor: pointer;
    }

    &.home {
      > .text {
        color: #fff;
      }

      > .img {
        background: url(./assets/img/index/hotline.png) no-repeat center center;
        background-size: 100% 100%;
      }
    }
  }
}

#content {
  clear: both;
  position: relative;
  padding-top: .72rem;
  min-height: 100%;

  &.home-content {
    padding-top: 0;
  }
}

.home-tel {
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4.7rem;
  background-color: #fff;
  cursor: pointer;

  > img {
    position: absolute;
    top: 0;
    height: 100%;
    object-fit: cover;
  }

  > p:nth-child(2) {
    font-weight: 500;
    font-size: 0.56rem;
    color: #FFFFFF;
    line-height: 0.78rem;
    z-index: 1;
  }

  > div:nth-child(3) {
    padding: 0.06rem 0.24rem;
    border-radius: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    margin-top: 0.12rem;
    z-index: 1;

    &:hover, &:active, &:focus {
      > .bg {
        height: 0;
      }

      > .mask {
        height: 100%;
      }
    }

    > .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all .4s cubic-bezier(.4, 0, .2, 1);
      z-index: 1;
      background-color: transparent;
    }

    > .mask {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: #7F4DC9;
      transition: all .4s cubic-bezier(.4, 0, .2, 1);
      z-index: 2;
    }

    > .text {
      z-index: 3;
      font-weight: 500;
      font-size: 0.28rem;
      color: #FFFFFF;
      line-height: 0.39rem;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        margin-left: 0.17rem;
        width: 0.24rem;
      }
    }
  }
}

.t20-other {
  position: relative;
  background: #FAFBFE;

  > .wireless {
    width: 100%;
    height: 100%;
    position: relative;
    background: #FAFBFE;

    > video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > .bg {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    > .title {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 0.56rem;
      color: #000;
      line-height: 0.78rem;
    }
  }

  > .design {
    width: 100%;
    position: relative;
    background: #FAFBFE;
    padding-top: 0.12rem;

    > .sub-title {
      font-weight: 500;
      font-size: 0.28rem;
      color: #000000;
      line-height: 0.39rem;
      text-align: center;
    }

    > .design-list {
      padding: 1.2rem 2rem;
      display: flex;
      flex-wrap: wrap;
      gap: 0.24rem;
      justify-content: center;

      > .design-item {
        border-radius: 0.24rem;
        background: #F5F5F7;
        overflow: hidden;

        &:hover {
          > .img-container {
            > img {
              transform: scale(1.1);
            }
          }
        }

        > .img-container {
          width: 4.73rem;
          overflow: hidden;
          display: flex;

          >img {
            width: 100%;
            transition: all 0.4s;
          }
        }

        > .title {
          font-weight: 500;
          font-size: 0.28rem;
          color: #000000;
          line-height: 0.39rem;
          margin-top: 0.2rem;
          text-align: center;
        }

        > .sub-title {
          font-size: 0.2rem;
          color: #666666;
          line-height: 0.28rem;
          margin-top: 0.12rem;
          text-align: center;
          padding-bottom: 0.2rem;
        }
      }
    }
  }

  > .research {
    width: 100%;
    height: 13.1rem;
    position: relative;
    background: #EBECF2;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .title {
      padding-top: 1.2rem;
      font-weight: 500;
      font-size: 0.56rem;
      color: #000000;
      line-height: 0.78rem;
      text-align: center;
      z-index: 2;
    }

    > .sub-title {
      margin-top: 0.12rem;
      font-weight: 500;
      font-size: 0.28rem;
      color: #000000;
      line-height: 0.39rem;
      text-align: center;
      z-index: 2;
    }

    > .research-btn {
      padding: 0 0.24rem;
      margin-top: 0.8rem;
      font-size: 0.28rem;
      color: #FFFFFF;
      line-height: 0.51rem;
      background: #000;
      border-radius: 10rem;
      z-index: 2;
    }

    > .research-bg {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  > .zhuanli {
    width: 100%;
    height: 8.94rem;
    position: relative;
    background: #FAFBFE;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .zhuanli-bg {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: 1;
    }

    > .title {
      padding-top: 1.2rem;
      font-weight: 500;
      font-size: 0.56rem;
      color: #000000;
      line-height: 0.78rem;
      text-align: center;
      z-index: 2;
    }

    > .sub-title {
      margin-top: 0.12rem;
      font-weight: 500;
      font-size: 0.28rem;
      color: #000000;
      line-height: 0.39rem;
      text-align: center;
      z-index: 2;
    }

    > .list {
      margin-top: 4.53rem;
      width: 100%;
      padding: 0 2.27rem;
      z-index: 2;
      display: flex;
      justify-content: space-between;

      > .item {
        width: 2.24rem;

        > p:nth-child(1) {
          font-weight: 500;
          font-size: 0.22rem;
          color: #000000;
          line-height: 0.31rem;
          text-align: center;
        }

        > p:nth-child(2) {
          margin-top: 0.12rem;
          font-size: 0.2rem;
          color: #999;
          line-height: 0.28rem;
          text-align: center;
        }
      }
    }
  }
}

.e10-other {
  position: relative;
  background: #FFF;

  > .sm-ck {
    width: 100%;
    height: 100%;
    position: relative;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    padding: 0.6rem 0.9rem 1.2rem;

    > .left {
      position: relative;
      width: 8.58rem;
      height: 4.83rem;
      border-radius: 0.24rem;
      overflow: hidden;

      > img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      > p {
        position: absolute;
        left: 0.4rem;
        bottom: 0.4rem;
        font-weight: 500;
        font-size: 0.28rem;
        color: #FFFFFF;
        line-height: 0.39rem;
      }
    }

    > .right {
      position: relative;
      width: 8.58rem;
      height: 4.83rem;
      border-radius: 0.24rem;
      overflow: hidden;

      > video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      > p {
        position: absolute;
        left: 0.4rem;
        bottom: 0.4rem;
        font-weight: 500;
        font-size: 0.28rem;
        color: #FFFFFF;
        line-height: 0.39rem;
      }
    }
  }

  > .full-video {
    position: relative;
    width: 100%;
    padding: 0 0.9rem;
    background: #fff;

    > video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.24rem;
    }

    >.title {
      text-align: center;
      font-weight: 500;
      font-size: 0.28rem;
      color: #FFFFFF;
      line-height: 0.39rem;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 1.1rem;
    }

    >.sub-title {
      text-align: center;
      font-size: 0.2rem;
      color: #FFFFFF;
      line-height: 0.28rem;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0.61rem;
    }
  }

  > .spec {
    position: relative;
    width: 100%;
    background: #fff;

    > .title {
      padding: 1.2rem 0;
      font-weight: 500;
      font-size: 0.56rem;
      color: #000000;
      line-height: 0.78rem;
      text-align: center;
    }

    > .person {
      position: absolute;
      bottom: 0;
      left: 1.37rem;
      width: 4.67rem;
    }

    > .content {
      position: relative;
      right: 0;
      padding: 0 0.9rem 1.2rem 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      > .top {
        position: relative;
        width: 10.84rem;
        height: 2.82rem;

        > img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        > .title {
          position: absolute;
          left: 0.4rem;
          top: 1.13rem;
          font-weight: 500;
          font-size: 0.4rem;
          color: #000000;
          line-height: 0.56rem;
        }
      }

      > .bottom {
        margin-top: 0.24rem;
        display: flex;

        > .left {
          position: relative;
          width: 5.3rem;
          height: 5.88rem;

          > img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          > .title {
            position: absolute;
            left: 0.4rem;
            top: 0.4rem;
            font-weight: 500;
            font-size: 0.4rem;
            color: #000000;
            line-height: 0.56rem;
          }
        }

        > .right {
          margin-left: 0.24rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          > .top, > .bottom {
            position: relative;
            width: 5.3rem;
            height: 2.82rem;

            > img {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            > .title {
              position: absolute;
              left: 0.4rem;
              top: 0.4rem;
              font-weight: 500;
              font-size: 0.4rem;
              color: #000000;
              line-height: 0.56rem;
            }

            > .sub-title {
              position: absolute;
              left: 0.4rem;
              top: 1rem;
              font-size: 0.28rem;
              color: #000000;
              line-height: 0.39rem;
            }
          }
        }
      }
    }
  }

  > .zhuanli {
    width: 100%;
    height: 8.94rem;
    position: relative;
    background: #FAFBFE;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .zhuanli-bg {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: 1;
    }

    > .title {
      padding-top: 1.2rem;
      font-weight: 500;
      font-size: 0.56rem;
      color: #000000;
      line-height: 0.78rem;
      text-align: center;
      z-index: 2;
    }

    > .sub-title {
      margin-top: 0.12rem;
      font-weight: 500;
      font-size: 0.28rem;
      color: #000000;
      line-height: 0.39rem;
      text-align: center;
      z-index: 2;
    }

    > .list {
      margin-top: 4.53rem;
      width: 100%;
      padding: 0 4.85rem 0 4.65rem;
      z-index: 2;
      display: flex;
      justify-content: space-between;

      > .item {
        width: 2.24rem;

        > p:nth-child(1) {
          font-weight: 500;
          font-size: 0.22rem;
          color: #000000;
          line-height: 0.31rem;
          text-align: center;
        }

        > p:nth-child(2) {
          margin-top: 0.12rem;
          font-size: 0.2rem;
          color: #999;
          line-height: 0.28rem;
          text-align: center;
        }
      }
    }
  }
}

#pageBottom {
  position: relative;
  height: 7.48rem;
  background: #000;
  padding: 0.8rem 0.9rem 0;
  flex-direction: column;

  .normal-text {
    font-weight: 500;
    font-size: 0.18rem;
    color: #FFFFFF;
    line-height: 0.25rem;
  }

  .small-text {
    font-weight: 400;
    font-size: 0.16rem;
    color: #999999;
    line-height: 0.22rem;

    &.active {
      color: #FFFFFF;
    }

    &:hover, &:focus, &:active {
      color: #FFFFFF;
    }
  }

  .big-text {
    font-weight: 500;
    font-size: 0.22rem;
    color: #FFFFFF;
    line-height: 0.31rem;
    letter-spacing: 0.01rem;
  }

  > .top {
    img {
      width: 1.66rem;
    }
  }

  > .content {
    flex: 1;
    padding-top: 0.8rem;
    display: flex;

    > .left {
      width: 4.8rem;

      > .social-media {
        > .list {
          margin-top: 0.1rem;
          display: flex;

          > .item {
            width: 0.44rem;
            height: 0.44rem;
            margin-left: 0.32rem;
            cursor: pointer;
            background-size: 100% !important;

            &.tiktok {
              background: url(./assets/img/index/social-media/tiktok.png) no-repeat center;

              &:hover, &:focus, &:active {
                background: url(./assets/img/index/social-media/tiktok-hover.png) no-repeat center;
              }
            }

            &.wechat {
              background: url(./assets/img/index/social-media/wechat.png) no-repeat center;

              &:hover, &:focus, &:active {
                background: url(./assets/img/index/social-media/wechat-hover.png) no-repeat center;
              }
            }

            &.xiaohongshu {
              background: url(./assets/img/index/social-media/xiaohongshu.png) no-repeat center;

              &:hover, &:focus, &:active {
                background: url(./assets/img/index/social-media/xiaohongshu-hover.png) no-repeat center;
              }
            }

            &.weibo {
              background: url(./assets/img/index/social-media/weibo.png) no-repeat center;

              &:hover, &:focus, &:active {
                background: url(./assets/img/index/social-media/weibo-hover.png) no-repeat center;
              }
            }

            &.zhihu {
              background: url(./assets/img/index/social-media/zhihu.png) no-repeat center;

              &:hover, &:focus, &:active {
                background: url(./assets/img/index/social-media/zhihu-hover.png) no-repeat center;
              }
            }

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      > .contact {
        margin-top: 0.8rem;
        display: flex;

        > .item {
          display: flex;
          flex-direction: column;

          > .big-text {
            margin-top: 0.12rem;
          }
        }

        > .gap {
          width: 0.48rem;
          display: flex;
          align-items: center;
          justify-content: center;

          > .line {
            width: 0.01rem;
            height: 0.55rem;
            background-color: #666666;
          }
        }
      }
    }

    > .center {
      flex: 1;
      display: flex;
      justify-content: center;

      > .link-group {
        margin-right: 0.6rem;

        &:last-child {
          margin-right: 0;
        }

        > .title {
          margin-bottom: 0.27rem;
        }

        > .link-list {
          > .link-item {
            margin-bottom: 0.28rem;
            cursor: pointer;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    > .right {
      width: 2.6rem;
      text-align: center;

      > .title {
        margin-bottom: 0.32rem;
      }

      > .app-list {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > .app-item {
          cursor: pointer;
          display: flex;
          flex-direction: column;

          > img {
            width: 1rem;
            margin-bottom: 0.12rem;
          }
        }
      }
    }
  }

  > .bottom {
    height: 1.23rem;
    border-top: 0.01rem solid rgba(255, 255, 255, 0.2);

    > p {
      font-size: 12px;
      color: #666666;
      line-height: 17px;
      text-align: center;

      &:nth-child(1) {
        margin-top: 0.24rem;
      }

      &:nth-child(2) {
        margin-top: 0.04rem;
      }

      &:nth-child(3) {
        margin-top: 0.2rem;
      }
    }
  }

  > .hover-img {
    position: absolute;
    background: #fff;
    width: 1.2rem;
    height: 1.2rem;
    padding: 0.06rem;
    border-radius: 0.12rem;

    > .angle {
      position: absolute;
      top: 100%;
      left: 0.53rem;
      width: 0.14rem;
      height: 0.08rem;
    }

    > img {
      width: 100%;
    }
  }
}

#goTop {
  position: fixed;
  z-index: 97;
  bottom: 1.15rem;
  right: 0.24rem;
  width: 0.52rem;
  height: 0.52rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;

  &.white {
    background: #222222;
  }

  > img {
    width: .26rem;
    height: .26rem;
  }
}
</style>
<style lang="scss">
@import './assets/css/common.scss';
</style>
