export default [{
  path: '/',
  redirect: '/home',
}, {
  path: '/home',
  component: () => import('../views/home.vue'),
}, {
  path: '/product',
  redirect: '/product/t20',
  component: () => import('../views/routerView.vue'),
  children: [{
    path: "t20",
    component: () => import('../views/product/t20.vue'),
  }, {
    path: "luolan",
    component: () => import('../views/product/luolan.vue'),
  }, {
    path: "mulan",
    component: () => import('../views/product/mulan.vue'),
  }, {
    path: "wrightinTwo",
    component: () => import('../views/product/wrightinTwo.vue'),
  }, {
    path: "wrightin",
    component: () => import('../views/product/wrightin.vue'),
  }, {
    path: "kegel",
    component: () => import('../views/product/kegel.vue'),
  }, {
    path: "wireless",
    component: () => import('../views/product/wireless.vue'),
  }, {
    path: "rule",
    component: () => import('../views/product/rule.vue'),
  }, {
    path: "e10",
    component: () => import('../views/product/e10.vue'),
  }, {
    path: "ekegel",
    component: () => import('../views/product/ekegel.vue'),
  }],
}, {
  path: '/app',
  component: () => import('../views/app.vue'),
}, {
  path: '/technology',
  component: () => import('../views/technology.vue'),
}, {
  path: '/about',
  redirect: '/about/info',
  component: () => import('../views/routerView.vue'),
  children: [{
    path: "info",
    component: () => import('../views/about/info.vue'),
  }, {
    path: "contact",
    component: () => import('../views/about/contact.vue'),
  }, {
    path: "join",
    component: () => import('../views/about/join.vue'),
  }, {
    path: "consultation",
    component: () => import('../views/about/consultation.vue'),
  }, {
    path: "consultation/detail/:code",
    component: () => import('../views/about/consultationDetail.vue'),
  }, {
    path: "responsibility",
    component: () => import('../views/about/responsibility.vue'),
  }],
}, {
  path: '/empower',
  component: () => import('../views/empower.vue'),
}]
